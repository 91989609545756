import React from 'react';
import SignUpForm from '../components/SignUpForm';

const SignUpPage = () => {
  return (
    <>
      {/* Styles for the background and player images */}
      <style>
        {`
          .background-image {
            background-image: url('https://scontent-atl3-1.xx.fbcdn.net/v/t39.30808-6/417106017_912560336899755_1848207272221287275_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=783fdb&_nc_ohc=3wjGTBzRdYAAX8bHbJ4&_nc_oc=AQl3WIVsFGgfzIGuh4LQjiDMC_uT4Iq937NtcEVI8a3odMLVILn8vMsM2cFjWp3ewjk&_nc_ht=scontent-atl3-1.xx&oh=00_AfChOK2NFbtIZ6tecaeLqxjXx15seQllzY2sEgfVcw4F9g&oe=65B0D84A');
            background-size: cover;
            background-position: center;
            position: relative;
          }
          .player-image {
            position: absolute;
            width: 30vw;
            height: auto;
            bottom: 0;
          }
          .mahomes {
            left: 5vw;
          }
          .kelce {
            right: 10vw;
          }
          .form-container {
            z-index: 10;
            position: relative;
            width: 90%;
            max-width: 400px;
            padding: 1rem;
            margin: auto;
            background: white;
            border-radius: 0.5rem;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          }
          @media (max-width: 768px) {
            .player-image, .kelce, .mahomes {
              display: none;
            }
            .form-container {
              width: 100%;
              padding: 2rem;
              margin-top: 2rem;
            }
          }
        `}
      </style>
      <div className="background-image min-h-screen flex justify-center items-center">
        {/* Mahomes image */}
        <img src="/mahomes.png" alt="Mahomes" className="player-image mahomes" />
        
        {/* Sign Up Form */}
        <div className="form-container">
          <SignUpForm />
        </div>
        
        {/* Kelce image */}
        <img src="/kelce.png" alt="Kelce" className="player-image kelce" />
      </div>
    </>
  );
};

export default SignUpPage;
