import React from 'react';
import SignUpPage from './pages/SignUpPage';

function App() {
  return (
    <div className="App">
      <SignUpPage />
    </div>
  );
}

export default App;
