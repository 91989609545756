import React, { useState } from 'react';
import QRCode from 'qrcode.react';

const SignUpForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    guests: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [qrCode, setQRCode] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading
  
    const { firstName, lastName, email, hasGuest } = formData;
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';
    
    // Helper function to check for mobile devices
    const isMobileDevice = () => {
      return /Mobi|Android/i.test(navigator.userAgent);
    };
    
    if (hasGuest) {
      // Construct the URL for when the guest checkbox is checked
      const bundleUrl = `https://wallet-pass-sandbox.bambumeta.software/brand-activation/600c9291?email=${encodeURIComponent(email)}&firstName=${encodeURIComponent(firstName)}&lastName=${encodeURIComponent(lastName)}`;
      
      // Open the bundle URL for guests in a new tab or display as QR code
      if (isMobileDevice()) {
        window.open(bundleUrl, '_blank');
      } else {
        setQRCode(bundleUrl);
      }
      
      setIsLoading(false);
    } else {
      // Construct the link-activation URL when no guest is checked
      const activationUrl = `https://wallet-pass-sandbox.bambumeta.software/link-activation/37683f9c?email=${encodeURIComponent(email)}&firstName=${encodeURIComponent(firstName)}&lastName=${encodeURIComponent(lastName)}`;
  
      // Open the activation URL in a new tab on mobile devices or display as QR code on desktop
      if (isMobileDevice()) {
        window.open(activationUrl, '_blank');
      } else {
        setQRCode(activationUrl);
      }
      
      setIsLoading(false);
    }
  };
  
  
  

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <img
        src="/header.png"
        alt="Chiefs Watch Party Header"
        className="w-full"
      />
      {isLoading ? (
        <div className="flex justify-center items-center w-full h-full">
          <img src="https://i.giphy.com/BTlFjXmkZNxlm8KntO.webp" alt="Loading..." />
        </div>
      ) : qrCode ? (
        <div className="flex flex-col items-center justify-center w-full">
          <QRCode value={qrCode} size={256} />
        </div>
        
      ) : (
        <>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            placeholder="First Name"
            className="block w-full px-4 py-2 mt-2 bg-white border rounded-md shadow-sm focus:border-red-500 focus:ring focus:ring-red-200 focus:ring-opacity-50"
            required
          />
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            placeholder="Last Name"
            className="block w-full px-4 py-2 mt-2 bg-white border rounded-md shadow-sm focus:border-red-500 focus:ring focus:ring-red-200 focus:ring-opacity-50"
            required
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            className="block w-full px-4 py-2 mt-2 bg-white border rounded-md shadow-sm focus:border-red-500 focus:ring focus:ring-red-200 focus:ring-opacity-50"
            required
          />
          <div className="flex items-center">
          <label className="block">
        <input
          type="checkbox"
          name="hasGuest"
          checked={formData.hasGuest}
          onChange={handleChange}
        />
        <span className="ml-2">Bringing a guest</span>
      </label>
        </div>

          <button
            type="submit"
            className="w-full px-4 py-2 text-white bg-red-600 rounded-md hover:bg-red-700 focus:bg-red-700 focus:outline-none"
          >
            Sign Up
          </button>
        </>
      )}
    </form>
  );
};

export default SignUpForm;
